body {
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: agrandir, montserrat !important;
}

@font-face {
  font-family: 'agrandir';
  src: url('../assets/fonts/Agrandir-Regular.otf') format('opentype');
  ascent-override: 103%;
  size-adjust: 97%;
  line-gap-override: 5%;
}

@font-face {
  font-family: 'montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  /* ascent-override: 103%;
  size-adjust: 97%;
  line-gap-override: 5%; */
}