body {
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: agrandir, montserrat !important;
}

@font-face {
  font-family: agrandir;
  src: url("Agrandir-Regular.934ea158.otf") format("opentype");
  ascent-override: 103%;
  size-adjust: 97%;
  line-gap-override: 5%;
}

@font-face {
  font-family: montserrat;
  src: url("Montserrat-Regular.5c4ab150.ttf") format("truetype");
}
/*# sourceMappingURL=index.6f3aae1a.css.map */
